@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700%7CRoboto:300,400,500,600,700');

@import 'react-toastify/scss/main.scss';

// Theme Styles
@import './sass/style.scss';

.example-highlight > pre {
    background: none transparent !important;
    margin: 0 !important;
    padding-top: 0.5rem;
    code {
        overflow-y: auto;
        display: block;

        span {
            background: none transparent !important;
        }
    }
}

.json > pre {
    background: none #fff !important;
}

.example-code > .json {
    background: none #fff !important;
}

.symbol.symbol-45 > svg {
    width: 100%;
    max-width: 45px;
    height: 45px;
}

.react-bootstrap-table {
    overflow-x: auto;
}

.react-bootstrap-table {
    th {
        outline: none;

        &.sortable {
            .svg-icon-sort {
                opacity: 0;
            }

            &:hover {
                cursor: pointer;

                .svg-icon-sort {
                    opacity: 1;
                }
            }
        }
    }

    .table.table-head-custom thead tr {
        .sortable-active {
            color: $primary !important;
        }
    }
}

.cursor-default {
    cursor: default !important;
}

.display-block {
    display: block;
}

#kt_quick_user_toggle .dropdown-toggle.nav-link:after,
.dropdown-toggle.btn:after {
    display: none;
}

.react-datepicker-wrapper {
    width: 100%;
}

.blockui {
    background: #ffffff;
    -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    border-radius: 0.42rem;
}
.blockui.blockui-noshadow {
    -webkit-box-shadow: none;
    box-shadow: none;
}
.blockui > span {
    color: #3f4254;
    padding: 0.75rem 1.2rem;
    font-size: 1rem;
    font-weight: 400;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
}
.blockui > span > .loader,
.blockui > span > .spinner {
    margin-right: 1.25rem;
}

.whatsapp {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 80px;
    right: 20px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 36px;
    z-index: 100;
    box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
}

.whatsapp:hover {
    color: #fff;
    background: #128c7e;
    transition: background 1.5s linear;
}

.whatsapp:focus {
    color: #fff;
}
